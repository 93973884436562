
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BlockMembers',
  data() {
    return {
      members: [
        {
          image: 'avatar',
          name: 'Name Surname',
          position: 'Your position',
        },
        {
          image: 'avatar',
          name: 'Name Surname',
          position: 'Your position',
        },
        {
          image: 'avatar',
          name: 'Name Surname',
          position: 'Your position',
        },
        {
          image: 'avatar',
          name: 'Name Surname',
          position: 'Your position',
        },
        {
          image: 'avatar',
          name: 'Name Surname',
          position: 'Your position',
        },
        {
          image: 'avatar',
          name: 'Name Surname',
          position: 'Your position',
        },
        {
          image: 'avatar',
          name: 'Name Surname',
          position: 'Your position',
        },
      ],
    }
  },
})
