
import { defineComponent } from 'vue'
import BlockHero from '@/components/Blocks/BlockHero.vue'
import BlockMosaic from '@/components/Blocks/BlockMosaic.vue'
import MosaicItem from '@/types/mosaic'
import BlockMembers from '@/components/Blocks/BlockMembers.vue'
import Slider from '@/types/slider'
import BlockSlider from '@/components/Blocks/BlockSlider.vue'

export default defineComponent({
  name: 'HomeView',
  components: { BlockHero, BlockMosaic, BlockMembers, BlockSlider },
  computed: {
    mosaicFirst(): MosaicItem[] {
      return [
        {
          title: this.$t('foundation_network_grants_title'),
          description: this.$t('foundation_network_grants_description'),
          image: 'grants',
          isBig: true,
          link: 'https://everscale.network/developers/grants',
        },
        {
          title: this.$t('foundation_network_ecosystem_title'),
          description: this.$t('foundation_network_ecosystem_description'),
          image: 'ecosystem',
          link: 'https://everscale.network/ecosystem',
        },
        {
          title: this.$t('foundation_network_developers_title'),
          description: this.$t('foundation_network_developers_description'),
          image: 'developers',
          link: 'https://everscale.network/developers',
        },
      ]
    },
    mosaicSecond(): MosaicItem[] {
      return [
        {
          title: this.$t('foundation_promotion_about_title'),
          description: this.$t('foundation_promotion_about_description'),
          image: 'about',
          isBig: true,
          link: 'https://everscale.network/about',
        },
        {
          title: this.$t('foundation_promotion_community_title'),
          description: this.$t('foundation_promotion_community_description'),
          image: 'community',
          link: 'https://everscale.network/community',
        },
        {
          title: this.$t('foundation_promotion_brand_title'),
          description: this.$t('foundation_promotion_brand_description'),
          image: 'brand',
          link: 'https://everscale.network/about/brand',
        },
      ]
    },
    slider(): Slider[] {
      return [
        {
          title: this.$t('foundation_slider_item_1'),
          image: 'SDK',
        },
        {
          title: this.$t('foundation_slider_item_2'),
          image: 'Faster',
        },
        {
          title: this.$t('foundation_slider_item_3'),
          image: 'CryptoCurrency',
        },
        {
          title: this.$t('foundation_slider_item_4'),
          image: 'Blockchain',
        },
        {
          title: this.$t('foundation_slider_item_5'),
          image: 'Game',
        },
        {
          title: this.$t('foundation_slider_item_6'),
          image: 'Wallet',
        },
      ]
    },
  },
})
