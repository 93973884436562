import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-41bb0a5e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "members" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "members__title" }
const _hoisted_4 = { class: "members__grid" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "members-item__text" }
const _hoisted_7 = { class: "members-item__name" }
const _hoisted_8 = { class: "members-item__position" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t('foundation_members_title')), 1),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.members, (member, memberIndex) => {
          return (_openBlock(), _createElementBlock("div", {
            key: memberIndex,
            class: "members-item"
          }, [
            _createElementVNode("img", {
              src: require(`@/assets/images/blocks/Members/${member.image}.png`),
              class: "members-item__img"
            }, null, 8, _hoisted_5),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, _toDisplayString(member.name), 1),
              _createElementVNode("div", _hoisted_8, _toDisplayString(member.position), 1)
            ])
          ]))
        }), 128))
      ])
    ])
  ]))
}